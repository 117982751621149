import React, {useState, useEffect, useRef} from 'react'
import home from '../images/home.png'
import leaderboard from '../images/leaderboard.png'
import reward from '../images/reward.png'
import FirstIndex from './FirstIndex'
import Leaderboard from './Leaderboard'
import axios from 'axios'
import pp from '../images/pp.png'
import donut from '../images/sec_badge.png'
import profile from '../images/profile.png'
import award from '../images/award.png'
import dollar from '../images/dollar-sign.png'
import card from '../images/credit-card.png'
import bell from '../images/bell.png'
import repeat from '../images/repeat.png'
import settings from '../images/settings.png'
import ms from '../images/message-square.png'
import file from '../images/file.png'
import alert from '../images/alert-octagon.png'
import logout from '../images/log-out.png'
import {motion, AnimatePresence} from 'framer-motion'
import Profile from '../components/Profile'
import menu from '../images/hamburger.png'
import { Link , useNavigate} from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import {RotatingTriangles} from  'react-loader-spinner'
// import award from '../images/award.png'

const Home = () => {
    const [bottomScroll, setBottomScroll] = useState(0)
    const [leader, getLeaders] = useState([])
    const [isVisible, setIsVisible] = useState(false)
    const [touchStart, setTouchStart] = useState(null)
    const [games, setGames] = useState([])
    const [height, setHeight] = useState(window.innerHeight)
    const [gameh, setGameh] = useState(0)
    const [bottom, setBottom] = useState(0)
    const [touchEnd, setTouchEnd] = useState(null)
    const [name, setName] = useState('')
    const [isloading, setisLoading] = useState(true)
    const [score, setScore] = useState(0)
    const date = new Date();
    const [month, setMonth] = useState(date.toJSON().slice(5, 7))
    const [year, setYear] = useState(date.getFullYear())
    const [language, setLanguage] = useState('')
    const [uid, setUid] = useState(null)
    const [open, setOpen] = useState(false)
    const [valid, setValid] = useState(false)
    const navigate = useNavigate()  

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery()

    console.log(query.get('msisdn'))
    


useEffect(()=>{

    if(query.get('op')=="korek"){
        axios.get(`https://redirect.kiq.highfivesgames.com/api/check-sub?msisdn=${query.get('msisdn')}`)
        .then((response)=>{
            console.log(response)
            if(response.data.result == 0){
                window.location.replace(response.data.redirectBack)
            }
            else{
                navigate('/login')
                setisLoading(false)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    else if(query.get('op')=="robi"){
        navigate('/login',{state:{op:"robi"}})
    }
    else if(query.get('op') == "oore"){
        navigate('/login',{state:{op:"oore"}})
    }
    else if(query.get('op')=='orange'){
        navigate('/login',{state:{op:"orange"}})
    }
    else{
        if(window.location.search.split('=')[1] == null){
            navigate('/login')
            setisLoading(false)
        }
        else{
            setUid(window.location.search.split('=')[1])
            console.log(window.location.search.split('=')[1])

        }
    }
},[])


useEffect(() =>{
    if(localStorage.getItem('language')){
        setLanguage(localStorage.getItem('language'))
      }
      else{
        setLanguage('english')
      }
    console.log(month, year)
    axios.get(`https://highfivesgames.com/leader/${year}/${month}`,{
        headers:{
            'x-operator':query.get('op')
        }
    })
    .then((response)=>{
        console.log(response)
        getLeaders(response.data.leaders)
    })
    .catch((err)=>{console.log(err)})
    // setGameh()
    setBottom(height/12)

    console.log(leader)
    
    

    console.log(bottom)


},[])

useEffect(()=>{
    console.log(uid)
    if(uid){
        if(localStorage.getItem('userId')){
            const u = localStorage.getItem('userId')
            const sessionId = localStorage.getItem('sessionId')
            axios.get(`https://highfivesgames.com/users/${u}`,{
                headers:{
                    'x-operator':query.get('op')
                }
            })
        .then((response)=>{
            console.log(response)
            setName(response.data.user.name)
            setScore(response.data.user.total_score)
            setisLoading(false)
            if(query.get('op')=="Korek"){
                axios.get('https://highfivesgames.com/users/session',{
                    headers:{
                        'X-SESSION-ID':sessionId
                    }
                })
                .then((response)=>{
                    setValid(true)
                })
                .catch((error)=>{
                    console.error(error)
                    setValid(false)
                })
            }
        })
        .catch((err)=>{console.log(err)})
        }
        else{
            axios.get('https://highfivesgames.com/users/guestlogin',{
                headers:{
                    'x-operator':query.get('op')
                }
            })
            .then((response) =>{
            localStorage.setItem('userId',response.data.user.id)
            localStorage.setItem('sessionId',response.data.user.session)
            axios.get(`https://highfivesgames.com/users/${response.data.user.id}`)
        .then((response)=>{
            console.log(response)
            setName(response.data.user.name)
            setScore(response.data.user.total_score)
            setisLoading(false)
        })
        .catch((err)=>{console.log(err)})
            })
            .catch((err)=>{console.log(err)})
        }
        }
},[uid])

// the required distance between touchStart and touchEnd to be detected as a swipe
// const minSwipeDistance = 50 

// const onTouchStart = (e) => {
//   setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
//   setTouchStart(e.targetTouches[0].clientX)
// }

// const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

// const onTouchEnd = () => {
//   if (!touchStart || !touchEnd) return
//   const distance = touchStart - touchEnd
//   const isLeftSwipe = distance > minSwipeDistance
//   const isRightSwipe = distance < -minSwipeDistance
//   if (isLeftSwipe){
//     setIsVisible(false)
//   }
  
// }


const handleLogout =()=>{
    axios.get('https://highfivesgames.com/users/logout')
    .then((response)=>{
        console.log(response)
        localStorage.clear()
        navigate('/login')
    })
    .catch((error)=>{console.log(error)})
    
}

function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsVisible(false)
          console.log("Outside")
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}

const wrapperRef = useRef(null);
useOutsideAlerter(wrapperRef);

  return (
    <div className='font-poppins max-w-[30rem]  w-screen mx-auto  h-screen  bg-[#2C3F66]'>
        {
        <div className="relative">
            {
                isloading?
                <div className="">
                    <div className="flex h-screen w-full justify-center items-center">
                    <RotatingTriangles></RotatingTriangles>
                    </div>
                </div>
                :
                <div>
                    <AnimatePresence>
        
        </AnimatePresence>
        {
            bottomScroll === 0 &&
            <div className="">
        <div className='flex sticky top-0 bg-[#2c3f66] items-center mx-4 justify-between pt-8 py-4'>
            <div className='flex items-center space-x-2'>
                <button onClick={()=>{setIsVisible(true)}}>
                <img src={menu} className="invert h-8"></img>
                </button>
                
                <img src={pp} className='h-12 '></img>
                
                
                <h1 className='text-white text-xl'>{name}</h1>
            </div>
            <div className='bg-[#8CC6AB] rounded-2xl space-x-2 flex items-center'>
            <div className='bg-white p-3 rounded-2xl'>
                <img src={donut} className="h-8"></img>
            </div>
            <h1 className='text-center pr-3'>{score}</h1>
            </div>
        </div>
        {
            isVisible &&
            <div className="sticky top-0 -mt-28 z-20">

            
            <motion.div ref={wrapperRef} className=" text-white absolute h-screen top-0 py-5 px-2 z-20 w-2/3 md:w-5/6 bg-black"
            initial={{x:-400, opacity:0}}
            animate = {{x:0,opacity:1}}
            exit={{x:-200, opacity:0}}
            >
                
                <div className='flex  items-center space-x-3'>
                <button onClick={()=>{setIsVisible(false)}}>
                    <img src={pp} className='h-16 '></img>
                </button>
                <div>
                <h1 className="text-2xl">{name}</h1>
                <h1>{
                        {
                        "english":"Non Premium",
                        "arabic":"غير مميز",
                        "french":"Non premium"
                    }[language]
                        }</h1>
                </div>
                </div>
                
                
                <div className="flex my- mx-3 mt-4 items-center space-x-2">
                    <img src={dollar} className="h-6"></img>
                    {/* <Link to="/subscribe"> */}
                    <h1 className="cursor-pointer">
                    {
                        {
                        "english":"Subscription",
                        "arabic": "الاشتراك",
                        "french":"Abonnement"
                    }[language]
                        }<span className="text-xs">
                            

{
                        {
                        "english":'(Coming Soon)',
                        "arabic": '(قريبًا)',
                        "french":"(À venir)"
                    }[language]
                        }
                            </span></h1>
                    {/* </Link> */}
                </div>
                <div className="flex my-4 mx-3 items-center space-x-2">
                    <img src={card} className="h-6"></img>
                    <h1 className="">
                        {
                        {
                        "english":'My Purchases',
                        "arabic": 'مشترياتي',
                        "french":"Mes achats"
                    }[language]
                        }
                        <span className="text-xs">{
                            {
                            "english":'(Coming Soon)',
                            "arabic": '(قريبًا)',
                            "french":"(À venir)"
                        }[language]
                            }</span></h1>
                </div>
                <div className="flex my-4 mx-3 items-center space-x-2">
                    <img src={bell} className="h-6"></img>
                    <h1 className="">
                    {
                        language == "english"
                        ?
                        'Notifications'
                        :
                        'الإخطارات'
                    }

                {
                        {
                        "english":'Notifications',
                        "arabic": 'الإخطارات',
                        "french":"Notifications"
                    }[language]
                        }
                    <span className="text-xs">{
                        {
                        "english":'(Coming Soon)',
                        "arabic": '(قريبًا)',
                        "french":"(À venir)"
                    }[language]
                        }</span></h1>
                </div>
                {/* <div className="flex my-4 mx-3 items-center space-x-2">
                    <img src={repeat} className="h-6"></img>
                    <h1 className="">
                    {
                            language == 'english'
                            ?
                            'Invite Friends'
                            :
                            'ادعو أصدقاء'
                        }
                    </h1>
                </div> */}
                {/* <div className="flex my-4 mx-3 items-center space-x-2">
                    <img src={settings} className="h-6"></img>
                    <h1 className="">Settings</h1>
                </div> */}
                {/* <h1 className="mx-3">Help</h1> */}
                <div className="flex my-4 mx-3 items-center space-x-2">
                    <img src={ms} className="h-6"></img>
                    <Link to="/contact">
                    <h1 className="">{
                        {
                        "english":"Contact Us",
                        "arabic":'اتصل بنا',
                        "french":"Contactez-nous"
                    }[language]
                        }
                           </h1>
                    </Link>
                </div>
                <div className="flex my-4 mx-3 items-center space-x-2">
                    <img src={file} className="h-6"></img>
                    <Link to="/terms">
                    <h1 className="cursor-pointer">{
                        {
                        "english": "Terms and Conditions",
                        "arabic":"الأحكام والشروط",
                        "french":"Termes et conditions"
                    }[language]
                        }
                    </h1>
                    </Link>
                </div>
                <div className="flex my-4 mx-3 items-center space-x-2">
                    <img src={alert} className="h-6"></img>
                    <Link to='/privacy'>
                    <h1 className="cursor-pointer">{
                        {
                        "english":"Privacy Policy",
                        "arabic":"سياسة الخصوصية",
                        "french":"Politique de confidentialité"
                    }[language]
                        }
                        </h1>
                    </Link>
                </div>
                <div className="flex my-4 mx-3 items-center space-x-2 ">
                    <img src={logout} className="h-6"></img>
                    <h1 className="cursor-pointer"><button onClick={handleLogout}>
                    {
                        {
                        "english":"Sign Out",
                        "arabic":"خروج",
                        "french":"Se déconnecter"
                    }[language]
                        }
                        </button>
                        </h1>
                </div>
                <div className="flex my-4 mx-3 items-center space-x-2">
                <div className="text-white mr-5 relative">
            <button onClick={()=>{setOpen(!open)}}>
            Language : {language}
            </button>
            {
              open &&
            <div className="flex flex-col bg-white text-black px-3 py-2 w-full rounded-xl absolute">
              <button onClick={()=>{
                setLanguage('arabic')
                localStorage.setItem('language','arabic')
              }}>
                <p>عربي</p>
                </button>
                <button onClick={()=>{
                setLanguage('english')
                localStorage.setItem('language','english')
              }}>
                <p>English</p>
                </button>
                <button onClick={()=>{
                setLanguage('french')
                localStorage.setItem('language','french')
              }}>
                <p>Français</p>
                </button>
            </div>
            }
          </div>
                   
                </div>

            </motion.div>
            </div>
        }
            <FirstIndex games={games}/>
            </div>
        }
        
        {
                bottomScroll === 1 && 
               <div>
                <Leaderboard list={leader}/>
               </div> 
            }
            {
                bottomScroll === 2 &&
                <div>
                    <Profile/>
                </div>
            }
        
        <div className={`fixed max-w-[30rem] w-screen px-1 bottom-0`}>
            <div className='bg-[#40468B] text-center text-white gap-x-10 tabs  grid grid-cols-3 rounded-xl justify-around items-center'>
                <button onClick={()=>{setBottomScroll(0)}}>
                <div className={bottomScroll == 0 && "bg-[#FF6356] tabs-item py-2 rounded-xl "}>
                    <img src={home} className="h-8 p-1 mx-auto"></img>
                    <h1 className="text-sm">
                    {
                        {
                        "english":"Home",
                        "arabic": "بيت",
                        "french":"Maison"
                    }[language]
                        }
                    </h1>
                </div>
                </button>
                <button onClick={()=>{setBottomScroll(1)}}>
                <div className={bottomScroll == 1 && "bg-[#FF6356] tabs-item py-2 rounded-xl "}>
                    <img src={award} className="h-8 p-1 mx-auto"></img>
                    <h1 className="text-sm text-center">{
                        {
                        "english":"Leaderboard",
                        "arabic": "ليدربورد",
                        "french":"Classement"
                    }[language]
                        }</h1>
                </div>
                </button>
                <button onClick={()=>{setBottomScroll(2)}}>
                <div className={bottomScroll == 2 && "bg-[#FF6356] tabs-item py-2 rounded-xl "}>
                    <img src={profile} className="h-8 p-1 mx-auto"></img>
                    <h1 className="text-sm">{
                        {
                        "english":"Profile",
                        "arabic":"حساب تعريفي",
                        "french":"Profil"
                    }[language]
                        }</h1>
                </div>
                </button>
            </div>
        </div>
                </div>
            }
        
        
        </div>
    }    
</div>
  )
}

export default Home

