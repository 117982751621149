import React,{useState, useEffect} from 'react'
import podium from '../images/podium.png'
import avatar1 from '../images/avatar_one.png'
import ScoreboardAllTime from './ScoreboardAllTime'
import axios from 'axios'
import { useLocation } from 'react-router-dom'

const Podium = (props) => {
    const {list} = props
    const [leaders, getLeaders] = useState()
    const uid  = window.localStorage.getItem('userId')
    const [name, setName] = useState('')
    const [score, setScore] = useState(0)

    useEffect(()=>{
        if(list){
            getLeaders(list)
        }
        else{
            getLeaders([])
        }
    },[list])

    
    const [language, setLanguage] = useState('')
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    
    let query = useQuery()
    
    console.log(query.get('msisdn'))
    
    useEffect(()=>{
        if(localStorage.getItem('language')){
            setLanguage(localStorage.getItem('language'))
        }
        else{
            setLanguage('english')
        }
    },[localStorage.getItem('language')])
    
    axios.get(`https://highfivesgames.com/users/${uid}`,{
        headers:{
            "x-operator":query.get('op')
        }
    })
    .then((response)=>{
        // setisLoading(false)
        console.log(response)
        setName(response.data.user.name)
        setScore(response.data.user.total_score)
    })
    .catch((err)=>{console.log(err)})
    // console.log(props.list)
    // getLeaders(props.list)
    console.log(leaders)
    return (
        <div>
        <div className="sticky top-[7.75rem] z-10 bg-[#2c3f66] py-1">
        <div className='bg-[#f0ecfc]   mx-4 my-2 text-center py-4 px-5 gap-x-10 rounded-xl justify-around items-center'>
                <div className="p-3 flex bg-white rounded-xl items-center space-x-6">
                    <div className="border-2 border-slate-400 rounded-full px-3 py-1">
                        <h1 className="text-slate-500 text-sm">4</h1>
                    </div>
                    <img src={avatar1} className="rounded-ful h-12"></img>
                    <div className='text-left '>
                        <h1 className="text-lg">{name}</h1>
                        <h1 className="text-slate-500 text-lg">{score} {
                        {
                        "english":"Points",
                        "arabic":"نقاط",
                        "french":"Les points"
                    }[language]
                        }</h1>
                    </div>
                </div>
            </div>
            </div>
        
        {/* <div className="bg-[#f96658] mt-3 px-3 py-2 rounded-xl text-md float-right text-white text-right mr-8">
            <h1>06d 23h 00m</h1>
        </div> */}
        <div className="">
        
        <div className="md:mt-[7.5rem] mt-[8rem]  relative" >
            <img src={podium} className="h-[13rem] mx-auto"></img>
            {/* <div className="absolute -top-16 text-center ml-auto">
                <div className="">
                <img src={`https://highfivesgames.com/${leaders[2].user_avatar}`} className='h-10 mx-auto rounded-full'></img>
                <h1 className='text-white'>{leaders[2].user_name}</h1>
                <h1 className="bg-[#8f87e5] float-left text-white text-center py-1 px-2 rounded-xl">{leaders[2].score} points</h1>
                </div>
            </div>
            <div className="absolute -top-28 text-center translate-x-3/4">
                <div className="">
                <img src={`https://highfivesgames.com/${leaders[0].user_avatar}`} className='h-10 mx-auto rounded-full'></img>
                <h1 className='text-white'>{leaders[0].user_name}</h1>
                <h1 className="bg-[#8f87e5] float-left text-white text-center py-1 px-2 rounded-xl">{leaders[0].score} points</h1>
                </div>
            </div>
            <div className="absolute -top-5 text-center right-[9rem]">
                <div className="fixed">
                <img src={`https://highfivesgames.com/${leaders[1].user_avatar}`} className='h-10 mx-auto rounded-full'></img>
                <h1 className='text-white'>{leaders[1].user_name}</h1>
                <h1 className="bg-[#8f87e5] float-left text-white text-center py-1 px-2 rounded-xl">{leaders[1].score} points</h1>
                </div>
            </div> */}
            <div className='absolute -top-16 bg-wite w-[20rem] left-1/2 -translate-x-1/2 grid grid-cols-3'>
            <div className="">
                <img src={`https://highfivesgames.com/${leaders[2]?.user_avatar}`} className='h-8 mx-auto rounded-full'></img>
                <h1 className='text-white text-sm text-center'>{leaders[2]?.user_name}</h1>
                <h1 className="bg-[#8f87e5] mx- md:mx-4 text-xs text-white text-center py-1 px-2 rounded-xl">{leaders[2].score} {
                        {
                        "english":"Points",
                        "arabic":"نقاط",
                        "french":"Les points"
                    }[language]
                        }</h1>
            </div>
            <div className="-mt-8 ">
                <img src={`https://highfivesgames.com/${leaders[0].user_avatar}`} className='h-8 mx-auto rounded-full'></img>
                <h1 className='text-white text-sm text-center'>{leaders[0].user_name}</h1>
                <h1 className="bg-[#8f87e5] text-xs md:mx-4 text-white text-center py-1 px-2 rounded-xl">{leaders[0].score} {
                        {
                        "english":"Points",
                        "arabic":"نقاط",
                        "french":"Les points"
                    }[language]
                        }</h1>
            </div>
            <div className="mt-8 ">
                <img src={`https://highfivesgames.com/${leaders[1].user_avatar}`} className='h-8 mx-auto rounded-full'></img>
                <h1 className='text-white text-sm text-center'>{leaders[1].user_name}</h1>
                <h1 className="bg-[#8f87e5] text-xs md:mx-4 text-white text-center py-1 px-2 rounded-xl">{leaders[1].score} {
                        {
                        "english":"Points",
                        "arabic":"نقاط",
                        "french":"Les points"
                    }[language]
                        }</h1>
            </div>
            </div>
        </div>
        <div className=" pb-10 -mt-16 bg-[#2c3f66] ">
        <div className="bg-[#ffe9e9] py-3 px-4 relative  my-4 mx-3 rounded-2xl">
        {
            leaders.map((leader) =>{
                return (
                    <div className="p-3 flex my-3 bg-white rounded-xl items-center space-x-6">
                    <div className="border-2 border-slate-400 rounded-full px-3 py-1">
                        <h1 className="text-slate-500">{leader.rank}</h1>
                    </div>
                    <img src={`https://highfivesgames.com/${leader.user_avatar}`} className="rounded-full h-14"></img>
                    <div className='text-left '>
                        <h1 className="text-xl">{leader.user_name}</h1>
                        <h1 className="text-slate-500">{leader.score} {
                        {
                        "english":"Points",
                        "arabic":"نقاط",
                        "french":"Les points"
                    }[language]
                        }</h1>
                    </div>
                </div>
                );
            })
        }
    </div>
        </div>
        </div>
        {/* <div className='absolute -bottom-1 max-w-[30rem] w-screen px-1'>
        <div className='bg-[#ffb380] mx-2 mt-4 rounded-xl text-white items-center flex'>
            <div className="bg-[#ff9b57] mx-4 my-2 rounded-2xl p-4">
                <h1 className='text-xl'>#4</h1>
            </div>
            <h1 className='text-xl mx-3 py-4'>You are doing better than 60% of the players!</h1>
        </div>
        </div> */}
    </div>
  )
}

export default Podium