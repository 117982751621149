import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { useLocation } from 'react-router-dom'
const Contact = () => {

    const uid  = window.localStorage.getItem('userId')
    const navigate = useNavigate()
    const [message, setMessage] = useState('')
    const [pop, setPop] = useState(false)
    const [language, setLanguage] = useState('')

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery()

    console.log(query.get('msisdn'))

    useEffect(()=>{
        if(localStorage.getItem('language')){
            setLanguage(localStorage.getItem('language'))
          }
          else{
            setLanguage('english')
          }
    },[localStorage.getItem('language')])
    const handleSubmit = (e) => {
        e.preventDefault()
        axios.post('https://highfivesgames.com/feedback',{
            user_id:uid,
            feedback:message
        },{
            headers:{
                'x-operator':query.get('op')
            }
        })
        .then((response)=>{
            console.log(response)
            setPop(true)
        })
        .catch((error) => {console.log(error)})
    }

    const handleok = ()=>{
        navigate('/home')
        setPop(false)
    }


  return (
    <div>
        <AnimatePresence>
        {
            pop &&

            <motion.div
            initial={{y:-400, opacity:0}}
            animate = {{y:0,opacity:1}}
            exit={{y:-200, opacity:0}}
             className="flex absolute h-screen w-full backdrop-filter backdrop-blur-sm   justify-center items-center">
                <div className="bg-[#FF6356] px-6 rounded-xl py-2 text-center">
                    <h1 className="text-center text-white ">
                    {
                        {
                        "english":"Feedback Submitted Successfully",
                        "arabic":"تم إرسال الملاحظات بنجاح",
                        "french":"Commentaires soumis avec succès"
                    }[language]
                        }
                    </h1>
                    <button className="my-3 bg-white text-[#FF6356] px-6 py-2 rounded-xl" onClick={handleok}>OK</button>
                </div>
            </motion.div>
        }
        </AnimatePresence>
    
    <div className="font-poppins max-w-[30rem]  w-screen mx-auto  min-h-screen  bg-[#2C3F66]">
        <h1 className="text-white text-center py-4 text-xl">
                    {
                        {
                        "english":"Connect With Us",
                        "arabic":"اتصل بنا",
                        "french":"Connectez-vous avec nous"
                    }[language]
                        }
                    </h1>
        <div className="mx-4 bg-[#FF6356] mt-5 rounded-2xl py-4 px-4 ">
            <h1 className="text-white">
                    {
                        {
                        "english":"How can we help you?",
                        "arabic":"كيف يمكننا مساعدتك؟",
                        "french":"Comment pouvons-nous vous aider?"
                    }[language]
                        }
                    </h1>
            <textarea className="w-full my-3 rounded-xl py-2 outline-none px-3" rows="10"></textarea>
            <div className="text-center">
            <button className="text-[#FF6356] border border-3 border-white px-6 py-2 rounded-xl bg-white" onClick={handleSubmit} value={message} placeholder="Enter your concern here" onChange={(e)=>{setMessage(e.target.value)}}>
            
                    {
                        {
                        "english":"Submit",
                        "arabic":"يُقدِّم",
                        "french":"Soumettre"
                    }[language]
                        }
            </button>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Contact