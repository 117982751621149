import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import ReactStars from "react-rating-stars-component";
import { Link } from 'react-router-dom';
import {RotatingTriangles} from  'react-loader-spinner'
const Howtoplay = () => {
    const location = useLocation()
    const { index } = location.state
    console.log(index)
    const[games, setGames] = useState([])
    const [bgcol, setBgcol] = useState('')
    const [width, setWidth] = useState(window.innerWidth)
    const [loader, setLoader] = useState(true)
    const uid  = window.localStorage.getItem('userId')
    const [name, setName] = useState('')
    useEffect(() =>{

        if(!localStorage.getItem('language')){
            localStorage.setItem('language','english')
        }
        axios.get(`https://highfivesgames.com/users/${uid}`)
    .then((response)=>{
        // setisLoading(false)
        setName(response.data.user.name)
        console.log(response)
        // setName(response.data.user.name)
    })
    .catch((err)=>{console.log(err)})

        axios.get('https://highfivesgames.com/games/')
        .then((response)=>{
            setGames(response.data.games)
            setBgcol(response.data.games[index].background_color.replace("0xFF","#"))
            // console.log(bgcol)
            // setNbg(bgcol.replace("0xFF", "#"))
            // console.log(newbg)
            setLoader(false)
        })
        .catch((err)=>{console.log(err)})
        
    },[])

    console.log(games[index])
    console.log(bgcol)
    // setBgcol(bgcol.replace("0xFF", "#"))
    console.log(width)
    
    

  return (
    <div style={{'backgroundColor':bgcol}} className='font-poppins max-w-[30rem] w-screen mx-auto pt-8 h-screen '>
        <div>
                {
                    !loader 
                    ? 
                    <div>
                        <div>
                        <img src={`https://highfivesgames.com/${games[index].new_image_path}`} className="h-48 mx-auto pt-6"></img>
                        </div>
                        <div className="bg-white h-screen mt-12 rounded-t-3xl px-6">
                            <h1 className="pt-4 font-bold">{games[index].name}</h1>
                            
                            {
                        {
                        "english":`Age ${games[index].min_age} -  ${games[index].max_age}`,
                        "arabic":`عمر ${games[index].min_age} -  ${games[index].max_age}`,
                        "french":`Âge ${games[index].min_age} -  ${games[index].max_age}`
                    }[localStorage.getItem('language')]
                        }
                        <div className="flex items-center space-x-2">
                            <ReactStars
                            className="pointer-events-none"
                            count={5}
                            value={games[index].rating.rating}
                            isHalf={true}
                            edit={false}
                            size={22}
                            ></ReactStars>
                            <h1 className="text-amber-400">{games[index].rating.responses}</h1>
                        </div>
                        
                        {
                        {
                            "english":
                            <div className="my-4">
                                <h1>{games[index].description}</h1>   
                            </div>,
                            "arabic":
                            <div className="mt-4">
                                <h1>{games[index].description_ar}</h1>   
                            </div>,
                            "french":
                            <div className="mt-4">
                                <h1>{games[index].description_fr}</h1>   
                            </div>
                        }[localStorage.getItem('language')]
                        }
                        <div className="">
                            <div className="md:absolute md:bottom-1 md:w-screen md:max-w-[30rem] md:pr-5">
                        {
                            width < 501 &&
                            <a href={`https://highfivesgames.com/${games[index].game_url}?playerInputSecond=${uid}&playerIdName=${name}&appGameId=${games[index].id}&language=${localStorage.getItem('language')}`}>
                                <button className="text-white w-full bg-[#FF6356] mt-4 py-4 rounded-xl">Play</button>
                            </a>    
                        }
                        {
                        width > 500 &&    
                        <Link to='/iframe'
                        state={{ index: index }} 
                        >
                        <button className="text-white w-full bg-[#FF6356] mt-4 py-4 rounded-xl">
                        {
                        {
                        "english":"Play",
                        "arabic":"يلعب",
                        "french":"Jouer"
                    }[localStorage.getItem('language')]
                        }
                        </button>
                        </Link>
                        }
                        
                        
                       
                        <div className="text-center underline my-3">
                        <a href={`https://storage.googleapis.com/hifives/${games[index].how_to.how_to_path}`}>
                        {
                        {
                        "english":"How to play?",
                        "arabic":"كيف تلعب؟",
                        "french":"Comment jouer?"
                    }[localStorage.getItem('language')]
                        }
                        </a>
                        </div>

                            </div>

                        </div>
                        </div>
                        
                    </div>
                    
                    : 
                    <div className="font-poppins max-w-[30rem] -mt-8  w-screen mx-auto   bg-[#2C3F66]">
          <div className="flex h-screen w-full justify-center items-center">
            <RotatingTriangles></RotatingTriangles>
          </div>
        </div>

                }
            </div>

    </div>
  )
}

export default Howtoplay