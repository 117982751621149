import React, {useState, useEffect} from 'react'
import pp from '../images/pp.png'
import edit from '../images/edit.png'
import star from '../images/star.png'
import clipboard from '../images/clipboard.png'
import donut from '../images/Donut.png'
import command from '../images/command.png'
import award from '../images/award.png'
import repeat from '../images/repeat.png'
import bar from '../images/bar-chart.png'
import dollar from '../images/dollar-sign.png'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
const Profile = () => {
    const [name, setName] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [score, setScore] = useState(0)
    const [gamesPlayed, setGamesPlayed] = useState(0)
    const uid  = window.localStorage.getItem('userId')
    const [language, setLanguage] = useState('')

    useEffect(()=>{
        if(localStorage.getItem('language')){
            setLanguage(localStorage.getItem('language'))
          }
          else{
            setLanguage('english')
          }
    },[localStorage.getItem('language')])
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery()

    console.log(query.get('msisdn'))
    useEffect(() =>{
        axios.get(`https://highfivesgames.com/users/${uid}`,{
            headers:{
                "x-operator":query.get('op')
            }
        })
        .then((response)=>{
            console.log(response)
            setName(response.data.user.name)
            setScore(response.data.user.total_score)
            setGamesPlayed(response.data.user.games_played)

        })
        .catch((error)=>{console.log(error)})
    },[])
  return (
    <div>
        
        <h1 className="text-center py-4 text-2xl text-white">

                        {
                        {
                        "english":"Profile",
                        "arabic":"حساب تعريفي",
                        "french":"Profil"
                    }[language]
                        }
       
       </h1>
        <div className="mx-4 ">
        <div className="flex space-x-4 items-center">
            <img src={pp} className="h-24"></img>
            <div className="flex w-full justify-between">
                <div>          
                <h1 className="text-white text-xl">{name}</h1>
                <h1 className="text-lg text-[#CD9AFF]">
       {
                        {
                        "english":"Non Premium",
                        "arabic":"غير مميز",
                        "french":"Non premium"
                    }[language]
                        }
       </h1>
       
                </div> 
                <img src={edit} className="h-5 "></img>
            </div>
        </div>
        </div>
        <div className="grid grid-cols-2 gap-4 md:gap-6 my-4 mx-4 ">
            <div className="bg-[#FC9BA1] rounded-xl items-center  justify-around px-8 space-x-4 flex py-2">
                <img src={star} className="md:h-10 h-8"></img>
                <div className="text-center text-md font-semibold">
                    <h1 className="text-sm pr-4">
       
       {
                        {
                        "english":"Points",
                        "arabic":"نقاط",
                        "french":"Points"
                    }[language]
                        }
       
       </h1>
                    <h1 className="pr-4">{score}</h1>
                </div>
            </div>
            <div className="bg-[#AD83E5] rounded-xl justify-evenly items-center  px-8 space-x-3 flex py-2">
                <img src={clipboard} className="md:h-10 h-8 invert"></img>
                <div className="text-center text-md font-semibold">
                    <h1 className="text-sm ">

                {
                        {
                        "english":"Leaderboard",
                        "arabic": "ليدربورد",
                        "french":"Classement"
                    }[language]
                        }
       
       </h1>
                    <h1>400</h1>
                </div>
            </div>
        </div>
        
        <div className="mx-4 text-white my-4 ">
            <h1 className="text-lg">
       {
                        {
                        "english":"Dashboard",
                        "arabic": "لوحة القيادة",
                        "french":"Tableau de bord"
                    }[language]
                        }
       
       </h1>
            <div className="grid text-sm grid-cols-2 gap-8  my-3">
                <div className="flex space-x-4 items-center">
                    <div className="bg-[#404c74] rounded-xl float-left p-4">
                        <img src={command} className="h-6"></img>
                    </div>
                    <div className="">
                        <h1 className="">

                        {
                        {
                        "english":"Games Played",
                        "arabic": "المباريات التي تم لعبها",
                        "french":"Jeux joués"
                    }[language]
                        }
                        
                        </h1>
                        <h1 className="opacity-90">{gamesPlayed}</h1>
                    </div>
                </div>
                <div className="flex space-x-4 items-center">
                    <div className="bg-[#404c74] rounded-xl float-left p-4">
                        <img src={star} className="h-6 invert"></img>
                    </div>
                    <div className="">
                        <h1 className="">
                        {
                        {
                        "english":"Lifetime Score",
                        "arabic": "نقاط مدى الحياة",
                        "french":"Score à vie"
                    }[language]
                        }
                        </h1>
                        <h1 className="opacity-90">{score}</h1>
                    </div>
                </div>
                {/* <div className="flex space-x-4 items-center">
                    <div className="bg-[#404c74] rounded-xl float-left p-4">
                        <img src={award} className="h-6"></img>
                    </div>
                    <div className="">
                        <h1 className="">Rewards Claimed</h1>
                        <h1 className="opacity-90">2</h1>
                    </div>
                </div> */}
                <div className="flex space-x-4 items-center">
                    <div className="bg-[#404c74] rounded-xl float-left p-4">
                        <img src={repeat} className="h-6"></img>
                    </div>
                    <div className="">
                        <h1 className="">
                        
                        {
                        {
                        "english":"Fav Game",
                        "arabic": "لعبة المفضلة",
                        "french":"Jeu préféré"
                    }[language]
                        }
                        </h1>
                        <h1 className="opacity-90">Gotcha</h1>
                    </div>
                </div>
                {/* <div className="flex space-x-4 items-center">
                    <div className="bg-[#404c74] rounded-xl float-left p-4">
                        <img src={bar} className="h-6"></img>
                    </div>
                    <div className="">
                        <h1 className="">Today's Challenge</h1>
                        <h1 className="opacity-90">4 weeks</h1>
                    </div>
                </div> */}
                <div className="flex space-x-4 items-center">
                    <div className="bg-[#404c74] rounded-xl float-left p-4">
                        <img src={dollar} className="h-6"></img>
                    </div>
                    <div className="">
                        <h1 className="">
                        
                        {
                        {
                        "english":"Subscription",
                        "arabic": "الاشتراك",
                        "french":"Abonnement"
                    }[language]
                        }
                        </h1>
                        <h1 className="opacity-90">No</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Profile